import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

function GettingAroundNZ(props) {
  return (
    <>
      <SEO title="Guide to Getting Around New Zealand - awesomeNZ" />
      <Layout
        layoutType="typography TourHolderPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../images/sliders/milfordsound-bus-lookout.jpg")}
                alt=""
                class="first last image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={false}
        hasSliderBlurSmall={true}
        content={
          <>
            <div id="TourHolderPage" class="clearfix">
              <div class="title-div">
                <h1>Getting around NZ</h1>
                <h3>Find out the best way to travel around New Zealand</h3>
              </div>
              <div class="destination-content">
                {/* ... */}
                <p>
                  <strong>
                    <Link to="/travel-advisory-coronavirus">
                      IMPORTANT: CORONAVIRUS TRAVEL ADVISORY
                    </Link>
                  </strong>
                </p>
                <p>
                  If you’re looking for a great way to meet real New Zealanders
                  as you explore this great country of ours, then travelling by
                  bus is one of the easiest and most cost effective ways to get
                  from A to B and everywhere in between.
                </p>
                <p>
                  Buses around New Zealand run regularly and many operators
                  provide drop-off points at the main backpacker accommodation
                  hubs, or close enough to be within walking distance.
                </p>
                <p>
                  The lack of a well-established rail network and expensive
                  domestic airfares means bus transport is a very popular choice
                  for many Kiwi travellers. Fares are very competitive and
                  provide flexibility, with fully refundable and non refundable
                  options available. Fares tend to be cheaper the earlier you
                  book and payment can be made online via credit card or at
                  hundreds of retail agents nationwide, including most
                  backpackers, hostels and information centres.
                </p>
                <p>
                  The main national bus company is{" "}
                  <a href="https://www.intercity.co.nz">
                    <strong>InterCity</strong>
                  </a>
                  , which operates New Zealand’s most comprehensive national
                  transport network with over 120 daily services through the
                  country to more than 600 towns, cities and communities.{" "}
                  <a href="https://skip.travel/">
                    <strong>Skip Bus</strong>
                  </a>
                  &nbsp;operates daily express bus service to major North Island
                  cities, like Auckland, Whangarei, Hamilton, Rotorua, Tauranga
                  and Wellington.
                </p>
                {/* ... */}
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default GettingAroundNZ
